import React from 'react';
import "./styles.css";
import loadingGif from "./loading.gif";

function LoadingAnimation(
  {
    children,
    movableLoading = false,
    ...rest
  }
) {
  return (
    <div
      className={`page-loading-animation-container${movableLoading ? " movable" : ""}`}
      {...rest}
    >
      <img
        className="img-responsive"
        width={100}
        height={100}
        src={loadingGif}
        alt="Carregando..."
      />
      {children}
    </div>
  );
}

export default LoadingAnimation;