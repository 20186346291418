import {useRecoilValue, useSetRecoilState} from "recoil";
import {selectedUnidadeState} from "../recoil/atoms/selectedUnidadeState";
import Card from "@mui/material/Card";
import {useDialog} from "../hooks/use-dialog";
import {SearchDialog} from "../components/SearchDialog/search-dialog";
import React, {useEffect} from "react";
import {Helmet} from 'react-helmet';
import useCsat from "../hooks/use-csat";
import Csat from "../components/Csat/Csat";
import {showSelectMatriculasState} from "../recoil/atoms/showSelectMatriculasState";

export default function withUnidade(Component, title = '', csatName = '') {
  return function _hoc(props) {
    const unidade = useRecoilValue(selectedUnidadeState);
    const setShowSelectmatriculas = useSetRecoilState(showSelectMatriculasState);
    const {
        addAnsweredCsat,
        isCsatVisible,
        handleShowCsat
    } = useCsat(csatName);
    const dialog = useDialog();

    useEffect(() => {
      setShowSelectmatriculas(false);
    }, [])

    useEffect(() => {
      if (!unidade) {
        dialog.handleOpen();
      }
    }, [unidade]);

    return (
        <>
            <Csat
                show={isCsatVisible}
                scoreTitle="O quanto você ficou satisfeito com: Movimentações na Secretaria Digital?"
                onSubmit={() => addAnsweredCsat(csatName)}
                onClosed={() => addAnsweredCsat(csatName)}
            />

            <Card
                sx={{
                    m: 2,
                    position: 'relative',
                    minHeight: '600px',
                    padding: '20px',
                }}
            >
                <Helmet>
                    <title>{title ? `${title} | ` : ''}Secretaria
                        Web</title>
                </Helmet>
                {!unidade ? (
                    <>
                        Selecione a unidade e matrícula
                    </>
                ) : (
                    <Component
                        {...props}
                        Unidade={unidade}
                        handleShowCsat={handleShowCsat}
                    />
                )}

                <SearchDialog
                    onClose={dialog.handleClose}
                    open={dialog.open}
                />
            </Card>
        </>
    );
  }
}
