import React, {ChangeEvent, useEffect, useMemo, useState} from "react";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import {Divider, Grow} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import SaveIcon from '@mui/icons-material/Save';
import * as _contatosService from "../../service/contatos-service";
import {pt} from 'yup-locale-pt';
import {useCan} from "../../hooks/use-can";
//@ts-ignore
import {Contato, ImageQualityReport} from "seb-graph-api-types/generated";
import FacialFoto from "../../components/ModalUploadFacialImage/FacialFoto";
import {ExibeMensagem} from "../../components/shared/Message";
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import {Matricula} from "../../components/MatriculaAutocompleteSelect/MatriculaAutocompleteSelect";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import csatList from "../../utils/csatList";
import {Unidade} from "../../components/UnidadeAutocompleteSelect/UnidadeAutocompleteSelect";
import {ModalUploadFacialImage} from "../../components/ModalUploadFacialImage/ModalUploadFacialImage";
import {useDialog} from "../../hooks/use-dialog";

Yup.setLocale(pt);

interface FormData {
  id?: string
  nome?: string
  email?: string
  cpf?: string
  telefoneFixo?: string
  celular?: string
  dataNascimento?: string
  fotoPrincipal64?: null | string
  fotoPrincipal?: any
}

const validationSchema = Yup.object<FormData>({
  nome: Yup.string().nullable(),
  email: Yup.string().email().nullable(),
  rg: Yup.string().nullable(),
  cpf: Yup.string().nullable(),
  telefoneFixo: Yup.string().nullable(),
  celular: Yup.string().nullable(),
  dataNascimento: Yup.string().nullable(),
  fotoPrincipal64: Yup.string().nullable(),
})

const initialValues: FormData = {
  id: null,
  nome: '',
  email: '',
  cpf: '',
  telefoneFixo: '',
  celular: '',
  dataNascimento: '',
  fotoPrincipal64: null,
  fotoPrincipal: null,
};

interface Props {
  Matricula: Matricula,
  Unidade: Unidade
  handleShowCsat: (key: string) => void
}

function ReconhecimentoFacial(props: Props) {
  const {
    Matricula,
    Unidade,
    handleShowCsat
  } = props;
  const dialog = useDialog();
  const {userCan} = useCan();
  const [loadingContacts, setLoadingContacts] = useState(false);
  const canCreate = false ?? userCan("cadastro.reconhecimentoFacial.create");
  const canEdit = false ?? userCan("cadastro.reconhecimentoFacial.edit");
  const canEditFoto = true ?? userCan("cadastro.reconhecimentoFacial.edit.foto");
  //@ts-ignore
  const [contato, setContato] = useState<Contato>(initialValues);
  const readOnlyFields = !(canEdit && contato.id !== null && !canCreate) && !(canCreate && contato.id === null)
  const readOnlyPhoto = !(canCreate && contato.id === null) && !(canEditFoto && contato.id !== null)
  const readOnly = readOnlyFields && readOnlyPhoto;
  const [saving, setSaving] = useState(false);
  const hasIdInContato = 'id' in contato && contato?.id && contato?.id?.length;
  const disabledFields = (readOnly && !('id' in contato)) || loadingContacts || saving || (!hasIdInContato && !canCreate);
  const reports = useMemo<ImageQualityReport>(
    () => contato?.fotoPrincipal?.props?.metadata?.analysisDecoded?.imageQualityReport ?? {} as ImageQualityReport,
    [contato]
  );
  const [showWebcam, setShowWebcam] = useState(false);
  
  const formik = useFormik({
    initialValues: {...initialValues, ...contato},
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        if (saving) return;

        setSaving(true);
        
        values.fotoPrincipal = values.fotoPrincipal64;
        delete values.fotoPrincipal64;
        // @ts-ignore
        delete values.tipoDeContatoValor;
        
        const resp = await _contatosService.Mutate({
          ...values,
          unidadeId: Unidade?.id
        });

        helpers.resetForm();

        // @ts-ignore
        setContato(initialValues);
        setContato(mapProps(resp))

        setSaving(false);
        ExibeMensagem("Contato alterado com sucesso!", "0", true);

      } catch (err) {
        console.error(err);
        setSaving(false);
      } finally {
        if (handleShowCsat) handleShowCsat(csatList.alteracaoCatracaContato)
      }
    }
  });
  const [contatos, setContatos] = useState<Contato[]>([]);
 
  const mapProps = (contato: Contato): Contato => {
    const values = {};

    Object.keys({...initialValues, ...contato}).map(key => {
      if (contato[key] !== null) {
        if (key === 'dataNascimento')
          contato[key] = contato[key]?.split("T")[0]
        
        if (key === 'fotoPrincipal64') {
          contato[key] = contato?.fotoPrincipal.publicURL
        }
        
        values[key] = contato[key]
      } else {
        values[key] = ""
      }
    })

    return values;
  }

  useEffect(() => {
    if (Matricula) {
      setLoadingContacts(true);
      _contatosService
        .GetByContatoMatriculaCRM(Matricula.idMatricula)
        .then(resp => {
          if (resp && resp.length) {
            setContatos(resp)
            const url = new URL(window.location.href)
            const contatoId = url.searchParams.get("contatoId")
            let c = resp.filter(r => r.id !== Matricula.idAluno)[0]
            if (contatoId) {
              c = resp.filter(r => r.id !== Matricula.idAluno).find(c => c.id === contatoId);
            }
            setContato(mapProps(window.location.pathname.includes("alunos") ? resp[0] : c))
          } else {
            setContatos([])
          }
        })
        .finally(() => {
          setLoadingContacts(false);
        })
    }
  }, [Matricula]);
  
  useEffect(() => {
    const url = new URL(window.location.href)
    const openUploadModal = url.searchParams.get("openUploadModal")
    const contatoId = url.searchParams.get("contatoId")
    
    if (openUploadModal && dialog && contato && contato?.id === contatoId && !showWebcam) {
      setShowWebcam(true);
      dialog.handleOpen();
    }
  }, [dialog, contato, showWebcam]);

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    const contato = (contatos || []).find(c => c?.id === value)
    setContato(mapProps(contato));
  }

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <form
          onSubmit={formik.handleSubmit}
        >
          <Stack
            spacing={3}
            sx={{ mb: 3 }}
          >
            <div>
              <Typography
                variant="h4"
                sx={{
                  mb: 3
                }}
              >
                Reconhecimento Facial
              </Typography>

              <Tabs
                indicatorColor="primary"
                onChange={handleTabsChange}
                scrollButtons="auto"
                textColor="primary"
                value={contato?.id ?? Matricula?.idAluno}
                variant="scrollable"
              >
                {(!contatos.length && Matricula) && (
                  <Tab
                    label="Carregando..."
                    value={Matricula?.idAluno}
                  />
                )}
                {contatos.filter(c => {
                  if (window.location.pathname.includes("alunos")) {
                    return c.id === Matricula?.idAluno
                  } else {
                    return c.id !== Matricula?.idAluno
                  }
                }).map((contato) => (
                  <Tab
                    key={contato.id}
                    label={contato.nome === Matricula?.estudante_Name && Matricula?.status === "Cancelada" ? `${Matricula?.estudante_Name} (${Matricula?.status})` : contato.nome}
                    value={contato.id}
                  />
                ))}
              </Tabs>
              <Divider />
            </div>
          </Stack>

          <Stack spacing={3}>
            <Stack
              spacing={4}
              useFlexGap
              flexWrap="wrap"
            >
              <Typography variant="h6">
                Informações do contato
              </Typography>
              <Grid
                container
                spacing={4}
                width="100%"
                justifyContent="center"
              >
                <Grid
                  container
                  md={6}
                  width="100%"
                  justifyContent="center"
                >
                  <Grow
                    in
                    style={{transformOrigin: '0 0 0'}}
                    {...{timeout: 1000}}
                  >
                    <Grid
                      md={6}
                    >
                      <FacialFoto
                        title="Foto 1"
                        url={formik?.values?.fotoPrincipal64}
                        finalScore={!formik.dirty ? reports?.finalScore : undefined}
                        disabled={saving || readOnlyPhoto || loadingContacts || Matricula?.status === "Cancelada"}
                        width={200}
                        height={300}
                        loading={loadingContacts}
                        onClick={dialog.handleOpen}
                        placeholder="Anexar Foto"
                        icon={null}
                        warning={Matricula?.status === "Cancelada" ? "Não é possível alterar foto de matrículas canceladas" : null}
                        blobProps={!formik.dirty ? contato?.fotoPrincipal?.props : undefined}
                      />
                      
                      <ModalUploadFacialImage
                        title="Foto 1"
                        contatoId={contato?.id}
                        url={formik?.values?.fotoPrincipal64}
                        finalScore={reports?.finalScore}
                        blobProps={contato?.fotoPrincipal?.props}
                        loading={loadingContacts}
                        onClose={dialog.handleClose}
                        open={dialog.open}
                        showStats={!formik.dirty}
                        showWebcam={showWebcam}
                        onUpload={(_, base64) => {
                          formik.setFieldValue('fotoPrincipal64', base64)
                        }}
                      />
                    </Grid>
                  </Grow>
                </Grid>

                <Grow
                  in={!loadingContacts}
                  easing="ease-out"
                  mountOnEnter
                  unmountOnExit
                  timeout={800}
                >
                  <Grid
                    md={6}
                    width="100%"
                  >
                    <Stack
                      direction={{md: 'row', sm: 'column'}}
                      spacing={3}
                      sx={{
                        mb: 3
                      }}
                    >
                      <TextField
                        error={!!(formik.touched.nome && formik.errors.nome)}
                        fullWidth
                        helperText={String((formik.touched.nome && formik.errors.nome) ?? "")}
                        label="Nome"
                        name="nome"
                        size="small"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.nome}
                        disabled={disabledFields}
                        variant={readOnlyFields ? "standard" : undefined}
                        inputProps={
                          {readOnly: readOnlyFields,}
                        }
                      />
                    </Stack>

                    <Stack
                      direction={{md: 'row', sm: 'column'}}
                      spacing={3}
                      sx={{
                        mb: 3
                      }}
                    >
                      <TextField
                        error={!!(formik.touched.email && formik.errors.email)}
                        fullWidth
                        helperText={String((formik.touched.email && formik.errors.email) ?? "")}
                        label="E-mail"
                        name="email"
                        size="small"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        disabled={disabledFields}
                        variant={readOnlyFields ? "standard" : undefined}
                        inputProps={
                          {readOnly: readOnlyFields,}
                        }
                      />
                    </Stack>

                    <Stack
                      direction={{md: 'row', sm: 'column'}}
                      spacing={3}
                      sx={{
                        mb: 3
                      }}
                    >
                      <TextField
                        error={!!(formik.touched.cpf && formik.errors.cpf)}
                        fullWidth
                        helperText={String((formik.touched.cpf && formik.errors.cpf) ?? "")}
                        label="CPF"
                        name="cpf"
                        size="small"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.cpf}
                        disabled={disabledFields}
                        variant={readOnlyFields ? "standard" : undefined}
                        inputProps={
                          {readOnly: readOnlyFields,}
                        }
                      />

                      <TextField
                        error={!!(formik.touched.dataNascimento && formik.errors.dataNascimento)}
                        fullWidth
                        helperText={String((formik.touched.dataNascimento && formik.errors.dataNascimento) ?? "")}
                        label="Data de Nascimento"
                        name="dataNascimento"
                        type="date"
                        size="small"
                        InputLabelProps={{shrink: true}}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.dataNascimento}
                        disabled={disabledFields}
                        variant={readOnlyFields ? "standard" : undefined}
                        inputProps={
                          {readOnly: readOnlyFields,}
                        }
                      />
                    </Stack>

                    <Stack
                      direction={{md: 'row', sm: 'column'}}
                      spacing={3}
                      sx={{
                        mb: 3
                      }}
                    >
                      <TextField
                        error={!!(formik.touched.celular && formik.errors.celular)}
                        fullWidth
                        helperText={String((formik.touched.celular && formik.errors.celular) ?? "")}
                        label="Telefone Celular"
                        name="celular"
                        size="small"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.celular}
                        disabled={disabledFields}
                        variant={readOnlyFields ? "standard" : undefined}
                        inputProps={
                          {readOnly: readOnlyFields,}
                        }
                      />

                      <TextField
                        error={!!(formik.touched.telefoneFixo && formik.errors.telefoneFixo)}
                        fullWidth
                        helperText={String((formik.touched.telefoneFixo && formik.errors.telefoneFixo) ?? "")}
                        label="Telefone Fixo"
                        name="telefoneFixo"
                        size="small"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.telefoneFixo}
                        disabled={disabledFields}
                        variant={readOnlyFields ? "standard" : undefined}
                        inputProps={
                          {readOnly: readOnlyFields,}
                        }
                      />
                    </Stack>
                  </Grid>
                </Grow>
              </Grid>

              <Grid
                container
                width="100%"
                justifyContent="flex-end"
              >
                <Grid
                  md={3}
                  sx={{
                    mt: 1
                  }}
                  width="100%"
                  justifyContent="center"
                >
                  <Grow
                    in={!loadingContacts}
                    easing="ease-out"
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      startIcon={saving ? <CircularProgress color="primary" size={20}/> : <SaveIcon/>}
                      disabled={(readOnly && !canEditFoto) || saving || !formik.dirty || (!hasIdInContato && !canCreate)}
                    >
                      {(hasIdInContato || (!hasIdInContato && !canCreate)) ? "Salvar" : "Criar"}
                    </Button>
                  </Grow>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </form>
      </Box>
    </React.Fragment>
  )
}

export default withUnidadeMatricula(
  ReconhecimentoFacial,
  "Reconhecimento Facial",
  csatList.alteracaoCatracaContato,
  "O quanto você ficou satisfeito com: Cadastro de fotos na Secretaria Digital?",
  {
    needMat: true,
    csatTitle: "O quanto você ficou satisfeito com: Cadastro de fotos na Secretaria Digital?",
  }
);
